import React from 'react'
import "../../styles/togglebtn.css"
import { HiMiniLanguage } from "react-icons/hi2";
const Togglebtn = (props) => {
    return (
        <div className='toolbox-wrapper'>
            <button className='glass' onClick={props.toggle}><HiMiniLanguage /></button>
        </div>
    )
}

export default Togglebtn