import React from "react";
import "../styles/complexcard.css";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const ComplexCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className="card-body">
      <div
        className="card-img"
        style={{ background: `url(${props.url})` }}
      ></div>
      <div className="bottom-card">

        <div className="bottom-card-top">
        <h3>{props.title}</h3>
        <p>{props.desc}</p>

        </div>
       
        {props.link && (
          <button
            className="btn-active"
            onClick={() => {
              navigate(`${props.link}`);
            }}
          >
            {props.btntext}
            <span>
              <FaArrowRight />
            </span>
          </button>
        )}

        {props.href && (
          // <a className="btn-active" target="_blank" href={props.href}>
            <a className="btn-active" href={props.href}>
            {props.btntext}
            <span>
              <FaArrowRight />
            </span>
          </a>
        )}
      </div>
    </div>
  );
};
export default ComplexCard;
