import React from "react";
import "../styles/herocard.css";
import { Tilt } from "react-tilt";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
const HeroCard = (props) => {
  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };

  return (
    <div className="herocard-container parent">
      <div
        className="container-small herocard-inner-container"
        style={{ flexDirection: `${props.align}` }}
      >
        <div className="herocard-left">
          <Tilt options={defaultOptions}>
            <div className="img-container">
              <div
                className="bg-img imgbg shine"
                style={{ background: `url(${props.url})` }}
              ></div>
            </div>
          </Tilt>
        </div>
        <div className="herocard-right">
          <h2 className="right-head">
            {props.title} <span>{props.span}</span>
          </h2>
          <p
            className="right-desc"
            dangerouslySetInnerHTML={{ __html: props.desc }}
          />
          {props.social_link && (
            <div className="hero-card-right-ctabox">
              <a href="#artist-contact" className="btn-active">
                {props.ctatext}
              </a>

              <div className="artists-links-box">
                <a href={props.social_link}>
                  <FaFacebookF />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
