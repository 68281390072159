import React from 'react'
import PageTop from "../components/PageTop";
import HeroCard from "../components/HeroCard";
import WaterSlider from "../components/Water_slider";
import environmenttp from "../Img/water/water_conservation.jpg"
import { Helmet } from "react-helmet";
import "../styles/water_conservation.css";
import environmenth from "../Img/water/jalsakshartarally3.JPG"
const Water_conservation = (props) => {
  return (
    <>
    {props.data.map((data, index) => {
        return (
            <>
               <Helmet>
              <title>"Akka Foundation's Water Literacy Rally: Uniting for Sustainable Water Conservation in Latur"</title>
              <meta name="description" content="Join Akka Foundation's Water Literacy Rally in Latur. Unite for water conservation. Be part of this impactful event for a sustainable future!" />
              <meta name="keywords" content="akka, akkafoundation, water conservation rally, latur, Water Literacy Rally" />
              <link rel="canonical" href="https://akkafoundation.in/water-conservation" />
            </Helmet>
            <PageTop
            text={data.w_c_title} url={environmenttp}
          />
          <HeroCard
              align="row"
              url={environmenth}
              desc={data.w_c_desc}
              span={data.w_c_span}
              title={data.w_c_subtitle}
            />
            <div className="water-independent parent">
                <div className="water-independent-container container-small">
                    <div className="water-left">
                    <h2>
                    {data.w_c_n_title} <span>{data.w_c_n_title1}</span>{" "}
                  </h2>
                  <p><ul>
                    <li>{data.w_c_n_text}</li>
                    <li>{data.w_c_n_text1}</li>
                    <li>{data.w_c_n_text2}</li>
                    <li>{data.w_c_n_text3}</li>
                    <li>{data.w_c_n_text4}</li>
                    <li>{data.w_c_n_text5}</li>
                    <li>{data.w_c_n_text6}</li>
                    </ul>
                    </p>
                    </div>
                    <div className="water-right">
                    <div className="water-img bg-img"></div>
                    </div>
                </div>
            </div>

            <div className="water-conservation-slider parent">
              <div className="water-conservation-slider-container container-small">
                <div className="water-slider-box">
                  <WaterSlider data={props.data} />
                </div>
              </div>
            </div>
            </>
            );
          })}
    </>
  );
}

export default Water_conservation
