import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/carousel.css';

const AboutCarousel = ({ images }) => {
  return (
    <div className='carousel-container-about'>
      <Swiper 
      pagination={{
        clickable: true,
      }}
       modules={[Pagination]}  
         className='mySwiper'>
        {images.map((image, index) => (
          <SwiperSlide key={index} className='slide' style={{ backgroundImage: `url(${image})` }}></SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AboutCarousel;
