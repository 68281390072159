import React from 'react'
import "../styles/simplecard.css"
const SimpleCard = (props) => {
    return (
        <div className='simple-card'>
            <h2>{props.title} <span>{props.span}</span></h2>
            <p>{props.desc}</p>
        </div>
    )
}

export default SimpleCard