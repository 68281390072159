import React from "react";
import PageTop from "../components/PageTop";
import HeroCard from "../components/HeroCard";
import JanjagarSlider from "../components/Janjagar_slider";
import { Helmet } from "react-helmet";
import environmenttp from "../Img/janjagar/3.webp";
import AboutCarousel from "../components/AboutCarousel";
import slideren1 from "../Img/janjagar/1.webp"
import slideren2 from "../Img/janjagar/2.webp"
import slideren3 from "../Img/janjagar/3.webp"
import slideren4 from "../Img/janjagar/4.webp"
import slideren5 from "../Img/janjagar/5.webp"
import slideren6 from "../Img/janjagar/6.webp"
import slideren7 from "../Img/janjagar/7.webp"

import "../styles/water_conservation.css";
import "../styles/janjagar.css";
import environmenth from "../Img/janjagar/1.webp";
const Janjagar_sanvad = (props) => {
  const images=[

    slideren1,
    slideren2,
    slideren3,
    slideren4,
    slideren5,
    slideren6,
    slideren7,
  ];
  return (
    
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet>
              <title>Akka Foundation: Empowering Lives Through Agriculture, Health, Education, and Social Developmen</title>
              <meta name="description" content="Explore Akka Foundation's impact in agriculture, health, education, water conservation, employment, social welfare, environment, women & child development, industry, arts, sports, & culture. Join us for positive change." />
              <meta name="keywords" content="akka, akkafoundation, Agriculture, Health, Education and skill development, Water conservation, Employment and Industry, Social Security and Welfare, Environment, Women and Child Development, Arts, Sports and Cultural" />
              <link rel="canonical" href="https://akkafoundation.in/janjagar-sanvad" />
            </Helmet>
            <PageTop text={data.j_j_title} url={environmenttp} />
            <HeroCard
              align="row"
              url={environmenth}
              desc={data.j_j_desc}
              span={data.j_j_span}
              title={data.j_j_subtitle}
            />
            <div className="janjagar-conservation-slider parent">
              <div className="janjagar-conservation-slider-container container-small">
                <div className="janjagar-slider-box">
                  <h2>{data.j_j_maintitle} <span>  {data.j_j_span}</span></h2>
                  <JanjagarSlider data={props.data} />
                </div>
              </div>
            </div>
            <div className="environment-carousel parent">
              <div className="environment-carousel-container container-small">
                <h2>
                  {data.ENP_fi_title} <span>{data.ENP_fi_span}</span>
                </h2>

                <div className="carousel-box">
                  <AboutCarousel images={images} />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default Janjagar_sanvad;
