import React from 'react'
import '../styles/footer.css';
import { Link } from 'react-router-dom';

import { MdPhone } from "react-icons/md";

import { HiOutlineLocationMarker } from "react-icons/hi";
import { TfiLocationPin } from "react-icons/tfi";
import { AiOutlineMail } from "react-icons/ai";

import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

function Footer(props) {
    return (
        <>

        {props.data.map((data,index)=>{
            return(
                <div key={index}>
                 <div className='footer-parent parent' >
                <div className='footer-container container'>
                    {/* Footer-Top */}
                    <div className='footer-top'>

                        {/* column 1 */}

                        <div className='col1 col'>
                            <p className='title'>{data.f_col1_title}</p>
                            <Link to='/empower' >{data.f_col1_li2}</Link>
                            <Link to='/education' >{data.f_col1_li3}</Link>
                            <Link to='/environment' >{data.f_col1_li4}</Link>
                            <Link to='/health' >{data.f_col1_li5}</Link>
                            <Link to='/art&culture' >{data.f_col1_li6}</Link>
                            <Link to='/project-Anandi' >{data.f_col1_li7}</Link>
                            <Link to='drushti' >{data.f_col1_li8}</Link>

                        </div>

                        {/* coumn 2 */}


                        <div className='col2 col'>
                            <p className='title'>{data.f_col2_title}</p>
                            <Link to='/donate' >{data.f_col2_li2}</Link>
                            <Link to='volunteer' >{data.f_col2_li3}</Link>
                            <Link to='/blogs' >{data.f_col2_li4}</Link>
                            <Link to='/gallery' >{data.f_col2_li5}</Link>
                            <Link to='/about'>{data.f_col2_li6}</Link>
                            <Link to='/contact' >{data.f_col2_li7}</Link>


                        </div>

                        {/* Column 3 */}


                        <div className='col3 col'>
                            <p className='title'>{data.f_col3_title}</p>
                            <Link to='/privacy-policy' >{data.f_col3_li2}</Link>
                            <Link to='cookies-policy' >{data.f_col3_li3}</Link>
                        </div>



                        {/* Column 4 */}
                        <div className='col4 column'>

                            <div className='icon-address '>
                                <span className='f-icon1 glass'><MdPhone /></span><span><Link to='tel:+447398814161'>  +91 7666601972</Link></span>
                            </div>

                            <div className='icon-address'>
                                <span className='f-icon1 glass'><AiOutlineMail /></span>
                                <span><Link to='mailto:info@akkafoundation.in'> info@akkafoundation.in <br /></Link>
                                <Link to='mailto:contact@akkafoundation.in'> contact@akkafoundation.in</Link></span>
                            </div>



                            <div className='icon-address '>
                                <span className='f-icon1 glass'><HiOutlineLocationMarker /></span><span><Link to='https://www.google.com/maps/search/office+no.214-216,+10+Biz+Park,+Near+Symbiosis+Law+College,+Airport+Road,+Vimannagar,+Pune/@18.5753164,73.9071212,17z/data=!3m1!4b1?entry=ttu' target='_blank'> Office no.214-216, 10 Biz Park, Near
                                    Symbiosis Law College, Airport Road,
                                    H.No.1-1/7 Ajanta Twakij, Nilanga -413521
                                </Link></span>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {/* Footer Bootom */}

            <div className='footer-bottom-parent parent'>
                <div className='footer-bottom-container container'>
                    <div className="social">

                        <Link to='https://www.facebook.com/akkafoundation/' className='f-icon' target="_blank">
                            <  FaFacebook />
                        </Link>

                        <Link    to='https://www.instagram.com/akka_foundation/?hl=en'  className='f-icon' target="_blank">
                            <FaInstagram />
                        </Link>
                        <Link to='https://twitter.com/i/flow/login?redirect_after_login=%2FFoundationAkka' className='f-icon' target="_blank">
                            <AiOutlineTwitter />
                        </Link>
                        <Link to='https://www.youtube.com/channel/UC4XG2-Y5n7BbtChwZobCjpA' className='f-icon' target="_blank">
                            <FaYoutube />
                        </Link>

                        <Link to='https://www.linkedin.com/company/akka-foundation/' className='f-icon' target="_blank">
                            <FaLinkedinIn />
                        </Link>
                    </div>
                    <p className='reserved'>Copyright @2023 All Rights Reserved | Akkafoundation | Designed & Developed by <Link to='https://diwise.uk' target='_blank'> Diwise</Link></p>
                </div>
            </div>




                </div>
            )
        })}   

        </>


    )
}

export default Footer