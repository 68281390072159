import React, { useState } from 'react'
import "../styles/volunteer.css"
import PageTop from "../components/PageTop"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import volunteertp from "../Img/volunteer.webp"
const Volunteer = (props) => {
  const [volunteer,setvolunteer] = useState({
    name : '',
    email : '',
    contact: '',
    message: ''
  })
  const submitHandler = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const date = currentDate.toLocaleDateString('en-IN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    const time = currentDate.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
    const newData = {
        ...volunteer,
        filled_date: date,
        filled_time: time,
      };
    if(volunteer.name !== "" && volunteer.email !== "" && volunteer.contact !== "")
    {
        axios.post(`${props.url}/volunteers/`, newData)
        .then(function (response) {
            toast.success('Your message has been submitted.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

                setvolunteer({
                  name : '',
                  email : '',
                  contact: '',
                  message: ''
                })
        })
        .catch();
    }
    else 
    {
        toast.error('Required fields must be filled', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }


}
    return (
        <>
        {props.data.map((data,index)=>{
            return(<>
            
            <PageTop text={data.vp_pt_title} url={volunteertp} />
            <div className="volunteer-parent parent">
                <div className="volunteer-container container-small">
                    <div className="volunteer-left">
                      <form onSubmit={submitHandler}>
                        <input type="name" placeholder='Name *' value={volunteer.name} onChange={(e)=>setvolunteer({...volunteer,name: e.target.value})}/>
                        <input type="email" placeholder='Email *' value={volunteer.email} onChange={(e)=>setvolunteer({...volunteer,email: e.target.value})}/>
                        <input type="number" placeholder='Phone *' value={volunteer.contact} onChange={(e)=>setvolunteer({...volunteer,contact: e.target.value})}/>
                        <textarea name="" id="" cols="5" rows="5" value={volunteer.message}  placeholder='Message' onChange={(e)=>setvolunteer({...volunteer,message: e.target.value})}></textarea>
                        <input type="submit" value="Submit" className='btn-active' />
                        </form>
                    </div>
                    <div className="volunteer-right">
                    <h2>{data.vp_title} <span>{data.vp_title_span}</span></h2>
                    <p>{data.vp_title_desc}</p>

                    </div>
                </div>
            </div>
            </>)
        })}
        <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>

    )
}

export default Volunteer