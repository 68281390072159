import React, { useEffect, useState } from "react";
import "../styles/homegallery.css"
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';
const Homegallery = (props) => {
  const defaultOptions = {
    reverse: false,
    max: 15,
    perspective: 1000,
    scale: 1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
    
};
  return (
    <>
    {props.data.map((data, index)=>{return(<>
    <div className="homegallery-parent parent" key={index}>
      <div className="homegallery-container container-small">
        <h2>{data.HP_og_title} <span>{data.HP_og_span}</span></h2>
        <div className="box-parent ">
        
          <Tilt options={defaultOptions} className="box1 img-box1 bg-img shine">
          <Link to="/gallery" className="overlay" onClick={() => { props.setmedia(false); props.setphoto(true); props.setvideo(false) }}>
              <h3>{data.HP_og_1}</h3>
            </Link>
          </Tilt>
        
          <Tilt options={defaultOptions} className="box2 img-box1 img2 bg-img shine">  <Link to="/gallery" className="overlay" onClick={() => { props.setmedia(false); props.setphoto(false); props.setvideo(true) }}>
            <h3>{data.HP_og_2}</h3>
          </Link> </Tilt>
         
          <Tilt options={defaultOptions} className="box3 img-box1 img3 bg-img shine">  <Link to="/gallery" className="overlay"  onClick={() => { props.setmedia(true); props.setphoto(false); props.setvideo(false) }}>
            <h3>{data.HP_og_3}</h3>
          </Link> </Tilt>
        
          <Tilt options={defaultOptions} className="box4 img-box1 img4 bg-img shine">  <Link to="/gallery" className="overlay" onClick={() => { props.setmedia(false); props.setphoto(true); props.setvideo(false) }}>
            <h3>{data.HP_og_4}</h3>
          </Link> </Tilt> 
        </div>
       
      </div>
    </div>
    </>)})}
    </>
  )
}

export default Homegallery