import React from 'react'
import "../styles/pagetop.css"
import CountUp from "react-countup";
const PageTop = (props) => {
    return (
        <div className='pagetop-body parent' style={{ background: `url(${props.url})` }}>
            <div className="overlay"></div>
            <div className="pagetop-container container-small">
                
                <h1>  {props.count && <CountUp end={props.cont}   /> }{props.text}</h1>
            </div>
        </div>
    )
}

export default PageTop