import React, { useEffect, useState } from "react";
import PageTop from "../components/PageTop";
import "../styles/environment.css";
import HeroCard from "../components/HeroCard";
import CountUp from "react-countup";
import { Tilt } from "react-tilt";
import { Helmet } from "react-helmet";
import BeforeFooter from "../components/BeforeFooter";
import AboutCarousel from "../components/AboutCarousel";
import slideren1 from "../Img/ourwork/en1.webp"
import slideren2 from "../Img/ourwork/en2.webp"
import slideren3 from "../Img/ourwork/en3.webp"
import slideren4 from "../Img/ourwork/en4.webp"
import slideren5 from "../Img/ourwork/en5.webp"
import environmenttp from "../Img/Environment.webp"
import environmenth from "../Img/ourwork/en-1.webp"
import { useInView } from "react-intersection-observer";
function Environment(props)  {
  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };
  const images=[

    slideren1,
    slideren2,
    slideren3,
    slideren4,
    slideren5,
  ];
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });


  useEffect(() => {
    if (inView) {
      setIsVisible(true);
 
    }
  }, [inView]);
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet> 
            <title>Green Harmony: Akka Foundation's Sustainable Initiatives for a Greener Latur </title>
            <meta name="keywords" content="green harmony, environmental health, akka foundation, environmental conservation, water security, rural development, soil conservation, tree planting campaigns, green latur, latur, latur jal sanjeevani, indraprastha jal bhumi mission, rain water harvesting, jalyukta shivar" />
            <meta name="description" content="Join Akka Foundation in creating a greener Latur through initiatives like tree planting, water conservation, and rural development. Secure our environment and water resources for a sustainable future. " />
            <link rel="canonical" href="https://akkafoundation.in/environment/" />
            
          </Helmet>
            <PageTop
              text={data.ENP_pt_title} url={environmenttp}
            />

            <HeroCard
              align="row"
              url={environmenth}
              desc={data.ENP_hc_desc}
              span={data.ENP_hc_span}
              title={data.ENP_hc_title}
            />

            <div className="environment-independent parent">
              <div className="container-small environment-independent-container">
                <div className="environment-left">
                  <h2>{data. ENP_is1_title}  <span> {data. ENP_is1_span}</span></h2>
                  
                  <p>
                  {data.ENP_is1_desc}
                  </p>

                  <div className="tree-count" ref={ref}>
                   {isVisible &&  <h4>
                      {" "}
                      <CountUp end={300000} />
                      <span>+</span>
                    </h4> }
                    <p>{data.ENP_is1_line1}</p>
                  </div>
                  <h4>{data.ENP_is1_para2}</h4>
                </div>
                <div className="environment-right">
                  <Tilt options={defaultOptions} className="environment-img bg-img shine"></Tilt>
                
                </div>
              </div>
            </div>

            <div className="environment-independent2 parent">
              <div className="container-small environment-independent2-container">
                <h2>
                {data.ENP_is2_title} <span>{data.ENP_is2_span} </span>
                </h2>
              </div>
            </div>
            <div className="environment-independent3 parent">
              <div className="container-small environment-independent3-container">
                <div className="environment3-main">
                  <div className="environment3-left">
                    <h2>
                    {data.ENP_is3_title}
                      <span>{data.ENP_is3_span}</span>
                    </h2>
                    <p>
                    {data.ENP_is3_desc1} <strong>{data.ENP_is3_desc2} </strong>{data.ENP_is3_desc3} 
                    </p>
                  </div>
                  <div className="environment3-right">
                    <Tilt options={defaultOptions} className="environment3-img bg-img shine"></Tilt>
                  </div>
                </div>
                <div className="environment3-stats">
                  <div className="stats1 card-list ">
                    <h3>{data.ENP_is4_card1_title}</h3>
                    <ul>
                      <li>{data.ENP_is4_card1_li1}</li>
                      <li> {data.ENP_is4_card1_li2}</li>
                      <li> {data.ENP_is4_card1_li3}</li>
                      <li> {data.ENP_is4_card1_li4}</li>
                      <li>
                      {data.ENP_is4_card1_li5}
                      </li>
                    </ul>
                  </div>
                  <div className="stats2 card-list ">
                    <h3>{data.ENP_is4_card2_title}</h3>
                    <ul>
                      <li>{data.ENP_card2_li1_is4}</li>
                      <li>{data.ENP_is4_card2_li2}</li>
                      <li>{data.ENP_is4_card2_li3}</li>
                      <li>{data.ENP_is4_card2_li4}</li>
                      <li>{data.ENP_is4_card2_li5}</li>
                    </ul>
                  </div>
                </div>
                <div className="environment4 card-list">
                    <h2>{data.ENP_is5_title} <span>{data.ENP_is5_span} </span></h2>
                    <p>{data.ENP_is5_desc}</p>

                </div>
              </div>
            </div>






            <div className="environment-carousel parent">
        <div className="environment-carousel-container container-small">
            <h2>{data.ENP_fi_title} <span>{data.ENP_fi_span}</span>
</h2>
          
          <div className="carousel-box">
          <AboutCarousel images={images}  />
          </div>
        </div>
      </div>
      <BeforeFooter  bgcolor="var(--darkbaiege)" data={props.data} />










          </>
        );
      })}
    </>
  );
}

export default Environment;
