import React, { useEffect, useState } from "react";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ArtImage from "../../components/artscomponents/ArtImage";
import ArtistContact from "../../components/artscomponents/ArtistContact";
import HeroCard from "../../components/HeroCard";
import PageTop from "../../components/PageTop";
import secondimg from "../../Img/artist/Ravishankar.png";
import img24 from "../../Img/Ravishankar/img10.jpeg";
import img25 from "../../Img/Ravishankar/img11.jpeg";
import img26 from "../../Img/Ravishankar/img12.jpeg";
import img27 from "../../Img/Ravishankar/img13.jpeg";
import img28 from "../../Img/Ravishankar/img14.jpeg";
import img16 from "../../Img/Ravishankar/img2.png";
import img17 from "../../Img/Ravishankar/img3.png";
import img18 from "../../Img/Ravishankar/img4.png";
import img20 from "../../Img/Ravishankar/img5.png";
import img19 from "../../Img/Ravishankar/img6.png";
import img21 from "../../Img/Ravishankar/img7.png";
import img22 from "../../Img/Ravishankar/img8.png";
import img23 from "../../Img/Ravishankar/img9.jpeg";
import "./sustainableart.css";
import toast from "react-hot-toast";
import axios from "axios";
import ArtSwiper from "./artscarousel/ArtSwiper";
import { Helmet } from "react-helmet";
const Ravishankar_Barskar = (props) => {
  const [toggle, settoggle] = useState(false);
  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };
  // const images = [
  //   img1,img2,img3,img4,img5,img6,img7,img8,img9, img10,img11,img12,img13
  // ];

  const [selectedId, setSelectedId] = useState(1);
  const imagedata = [
    {
      id: 16,
      image: img16,
    },
    {
      id: 17,
      image: img17,
    },
    {
      id: 18,
      image: img18,
    },
    {
      id: 19,
      image: img19,
    },
    {
      id: 20,
      image: img20,
    },
    {
      id: 21,
      image: img21,
    },
    {
      id: 22,
      image: img22,
    },
    {
      id: 23,
      image: img23,
    },
    {
      id: 24,
      image: img24,
    },
    {
      id: 25,
      image: img25,
    },
    {
      id: 26,
      image: img26,
    },
    {
      id: 27,
      image: img27,
    },
    {
      id: 28,
      image: img28,
    },
  ];

  const [fetcheddata, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.akkafoundation.in/api/arts/?artist_id=3&language_id=${
          props.toggle ? 2 : 1
        }`
      );
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      toast.error("Server offline!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.toggle]);
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <Helmet>
              <title>
              Ravi Shankar Baraskar - Acclaimed Artist from Yermala
              Village | Rangoli, Murals, and Betel Nut Paintings
              </title>
              <meta
                name="description"
                content="Discover the remarkable story of Ravi Shankar Baraskar
            from Yermala village, Dharashiv district. Overcoming physical
            challenges, Ravi Shankar has achieved acclaim for his grand
            rangolis, murals, and betel nut paintings. His dedication has earned
            him numerous awards, including second place in the Swachh Survekshan
            2022 mural competition."
              />
              <meta
                name="keywords"
                content="Ravi Shankar Baraskar, artist from Yermala, rangoli art, mural paintings, betel nut
            paintings, Divyang artist, Swachh Survekshan 2022, Majhi Vasundhara
            Abhiyan, award-winning artist, artistic achievements"
              />
              <link
                rel="canonical"
                href="https://akkafoundation.in/sustainableArt/Ravishankar_Barskar"
              />
            </Helmet>
            <PageTop text={data.artist_name3} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.divyashri_intro}
              span={data.ravishankar_span}
              title={data.ravishankar_heading}
              ctatext={data.ravishankar_cta}
              social_link1="https://www.instagram.com/invites/contact/?igsh=1c68z3xns8qyi&utm_content=3megyy9"
            />
            <div className="mangesh parent">
              <div className="mangesh-container container-small">
                {/* {fetcheddata &&
                  fetcheddata.map((item, index) => (
                    <ArtImage
                      key={index}
                      open={open}
                      image={item.image}
                      onClick={() => setSelectedId(item.id)}
                    />
                  ))} */}
                <ArtSwiper
                  data={fetcheddata}
                  open={open}
                  set={setSelectedId}
                  toggle={props.toggle}
                />
              </div>
            </div>
            {toggle && (
              <ArtDetail close={close} id={selectedId} data={props.data} />
            )}
          </>
        );
      })}

      <ArtistContact toggle={props.toggle} />
    </>
  );
};

export default Ravishankar_Barskar;
