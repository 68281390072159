import React from "react";
import "../styles/about.css";
import PageTop from "../components/PageTop";
import HeroCard from "../components/HeroCard";
import SimpleCard from "../components/SimpleCard";
import ComplexCard from "../components/ComplexCard";
import BeforeFooter from "../components/BeforeFooter";
import AboutCarousel from "../components/AboutCarousel";
import aboutimg from "../Img/pt-ab.webp";
import firstimg from "../Img/gallery-page/39.webp";
import secondimg from "../Img/akka.webp";
import founderone from "../Img/foundres/Spn.webp";
import foundertwo from "../Img/foundres/Apn.webp";
import founderthree from "../Img/foundres/contact.webp";
import sliderone from "../Img/gallery-page/18.webp";
import slidertwo from "../Img/gallery-page/29.webp";
import sliderthree from "../Img/gallery-page/01.webp";
import sliderfour from "../Img/gallery-page/36.webp";
import sliderfive from "../Img/gallery-page/17.webp";
import slidersix from "../Img/gallery-page/21.webp";
import sliderseven from "../Img/gallery-page/48.webp";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const About = (props) => {
  const images = [
    sliderone,
    slidertwo,
    sliderthree,
    sliderfour,
    sliderfive,
    slidersix,
    sliderseven,
  ];
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
            <Helmet>
              <title>Empowering Lives: Akka Foundation and Our Vision for Women's Empowerment, Anandi, Drushti Abhiyan, and Health Guidelines</title>
              <meta name="description" content="Discover Akka Foundation's mission – empowering women, initiatives like Anandi and Drushti Abhiyan, and health guidelines. Meet the founders: Sambhaji Patil Nilangekar, Arvind Patil Nilangekar, and Prajakta Ashish Marwaha. Join us in creating a better future together." />
              <meta name="keywords" content="akka, akkafoundation, womens empowerment, anandi, drushti abhiyan, founders, sambhaji patil nilangekar, arvind patil nilangekar, prajakta ashish marwaha,health guidlines" />
              <link rel="canonical" href="https://akkafoundation.in/about" />
            </Helmet>
            <PageTop text={data.AP_pt_title} url={aboutimg} />
            <HeroCard
              align="row"
              url={firstimg}
              desc={data.AP_hc_desc}
              span={data.AP_hc_span}
              title={data.AP_hc_title}
            />
            <div className="vision parent">
              <div className="vision-container container-small">
                <div className="vision-left">
                  <SimpleCard
                    desc={data.AP_ov_desc}
                    title={data.AP_ov_title}
                    span={data.AP_ov_span}
                  />
                  <SimpleCard
                    desc={data.AP_oh_desc}
                    title={data.AP_oh_title}
                    span={data.AP_oh_span}
                  />
                </div>
                <div className="vision-right">
                  <h2>
                    {data.AP_om_desc_title}{" "}
                    <span>{data.AP_om_desc_title_span}</span>
                  </h2>
                  <ul>
                    <li>
                      <p>{data.AP_om_desc_li1}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li2}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li3}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li4}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li5}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li6}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li7}</p>
                    </li>
                    <li>
                      <p>{data.AP_om_desc_li8}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <HeroCard
              align="row-reverse"
              url={secondimg}
              desc={data.AP_oi_desc}
              span={data.AP_oi_span}
              title={data.AP_oi_title}
            />
            <div className="founder parent">
              <div className="founder-container container-small">
                <h2>
                  {data.AP_founder}
                  <span>{data.AP_founder_span}</span>
                </h2>
                <div className="founder-cards">
                  <ComplexCard
                    url={founderone}
                    title={data.AP_of_box1_title}
                    desc={data.AP_of_box1_desc}
                    btntext={data.AP_btn_box}
                    href="https://sambhajipatilnilangekar.com"
                  />
                  <ComplexCard
                    url={foundertwo}
                    title={data.AP_of_box2_title}
                    desc={data.AP_of_box2_desc}
                    btntext={data.AP_btn_box}
                    href="https://arvindpatilnilangekar.com"
                  />
                  <ComplexCard
                    url={founderthree}
                    title={data.AP_of_box3_title}
                    desc={data.AP_of_box3_desc}
                    btntext={data.AP_btn_box}
                    href="https://prajaktamarwaha.com"
                  />
                </div>
              </div>
            </div>

            <div className="about-slider parent">
              <div className="aboutslider-container container-small">
                <h2>
                  {" "}
                  {data.AP_slider_title}{" "}
                  <span>{data.AP_slider_title_span}</span>
                </h2>
                <div className="slider-box">
                  <AboutCarousel images={images} />
                </div>
              </div>
            </div>
            <BeforeFooter data={props.data} />
          </>
        );
      })}
    </>
  );
};

export default About;
