import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "./artswiper.scss";

const ArtSwiper = ({ data ,open, set, toggle}) => {
  console.log(data);
  return (
    <div className="card-parent">
        {
            !toggle ? <h2>A glimpse into our <span>finest works</span></h2> : <h2>आमच्या <span>उत्कृष्ट कामांची</span>  एक झलक</h2>
        }
        

      <div className="card-box">
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false, // Allows Swiper to pause on interaction
            pauseOnMouseEnter: true, // Stops autoplay on mouse enter
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper test-swiper"
          breakpoints={{
            300: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 2, spaceBetween: 15 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {data &&
            data.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="card-image bg-img"
                  style={{
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  onClick={() => { set(item.id); open(); }}
                ></div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ArtSwiper;
