import React, { useEffect, useState } from "react";
import "./sustainableart.css";
import HeroCard from "../../components/HeroCard";
import secondimg from "../../Img/artist/Divyashri.png";
import PageTop from "../../components/PageTop";
import Mangesh from "../../components/Mangesh";
import ArtImage from "../../components/artscomponents/ArtImage";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ComplexCard from "../../components/ComplexCard";
import img1 from "../../Img/divyashri/img1.png";
import img2 from "../../Img/divyashri/img3.jpeg";
import img3 from "../../Img/divyashri/img4.png";
import img4 from "../../Img/divyashri/img5.png";
import img5 from "../../Img/divyashri/img6.png";
import img6 from "../../Img/divyashri/img7.png";
import img7 from "../../Img/divyashri/img8.png";
import img8 from "../../Img/divyashri/img9.png";
import ArtistContact from "../../components/artscomponents/ArtistContact";
import axios from "axios";
import toast from "react-hot-toast";
import ArtSwiper from "./artscarousel/ArtSwiper";
import { Helmet } from "react-helmet";

const Divyashri_and_Vyankatesh = (props) => {
  const [toggle, settoggle] = useState(false);
  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };

  // const images = [
  //   img1,img2,img3,img4,img5,img6,img7,img8
  // ];
  const [selectedId, setSelectedId] = useState(1);
  const imagedata = [
    {
      id: 1,
      image: img1,
    },
    {
      id: 2,
      image: img2,
    },
    {
      id: 3,
      image: img3,
    },
    {
      id: 4,
      image: img4,
    },
    {
      id: 5,
      image: img5,
    },
    {
      id: 6,
      image: img6,
    },
    {
      id: 7,
      image: img7,
    },
    {
      id: 8,
      image: img8,
    },
  ];

  const [fetcheddata, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.akkafoundation.in/api/arts/?artist_id=1&language_id=${
          props.toggle ? 2 : 1
        }`
      );
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      toast.error("Server offline!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.toggle]);
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <Helmet>
              <title>
                Divyashri - Rangoli Artist in Latur | Artistry Beyond Words
              </title>
              <meta
                name="description"
                content="Discover the artistic journey of Divyashri, a rangoli artist in Latur born without the ability to speak. Her colorful creations and
            passion for handicrafts and painting reflect a vibrant spirit.
            Explore how her art, alongside her partner Venkatesh, transcends
            words and connects hearts."
              />
              <meta
                name="keywords"
                content="Divyashri, rangoli
            artist, Latur rangoli, colorful rangoli designs, handicrafts,
            painting, art without words, Venkatesh, vibrant art, artistic
            journey, universal language of art"
              />
              <link rel="canonical" href="https://akkafoundation.in/sustainableArt/Divyashri_and_Vyankatesh" />
            </Helmet>

            <PageTop text={data.artist_name2} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.divyashri_intro}
              span={data.divyashri_span}
              title={data.divyashri_heading}
              ctatext={data.divyashri_cta}
              // social_link="https://www.facebook.com/mangesh.nipanikar.9"
            />
            <div className="mangesh parent">
              <div className="mangesh-container container-small">
                {/* {fetcheddata &&
                  fetcheddata.map((item, index) => (
                    <ArtImage
                      key={index}
                      open={open}
                      image={item.image}
                      onClick={() => setSelectedId(item.id)}
                    />
                  ))} */}
                <ArtSwiper
                  data={fetcheddata}
                  open={open}
                  set={setSelectedId}
                  toggle={props.toggle}
                />
              </div>
            </div>
            {toggle && (
              <ArtDetail close={close} id={selectedId} data={props.data} />
            )}
          </>
        );
      })}
      <ArtistContact toggle={props.toggle} />
    </>
  );
};

export default Divyashri_and_Vyankatesh;
