import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/water--slider.css";

// import required modules
import { Autoplay, Pagination} from "swiper/modules";
import SimpleCard from "./SimpleCard";
import img1 from '../Img/water/jalsakshartarally2.JPG'
import img2 from '../Img/water/jalsakshartarally.JPG'
import img3 from '../Img/water/jalsakshartarally1.JPG'
function WaterSlider(props) {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
      {props.data.map((data,index)=> {
        return(
          <>
          <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500000000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
        <SwiperSlide>
          <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img1})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.w_c_slider_title} <span> {data.w_c_slider_span}  </span> </h3>

            <ul>
              <li>{data. w_c_slider_text1}</li>
              <li>{data.w_c_slider_text2}</li>
              <li>{data.w_c_slider_text3}</li>
            </ul>
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img2})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.w_c_slider2_title} <span> {data.w_c_slider2_span}  </span> </h3>

            <ul>
              <li>{data.w_c_slider_text4}</li>
              <li>{data.w_c_slider_text5}</li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img3})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.w_c_slider3_title} <span> {data.w_c_slider3_span}  </span> </h3>

            <ul>
              <li>{data.w_c_slider_text6}</li>
              <li>{data.w_c_slider_text7}</li>
            </ul>
          </div>
        </SwiperSlide> 
      </Swiper>
          </>
        )
      })}
    </>
  );
}

export default WaterSlider;
