import React, { useEffect, useState } from "react";
import "./sustainableart.css";
import HeroCard from "../../components/HeroCard";
import secondimg from "../../Img/artist/Mangesh.png";
import PageTop from "../../components/PageTop";
import Mangesh from "../../components/Mangesh";
import ArtImage from "../../components/artscomponents/ArtImage";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ComplexCard from "../../components/ComplexCard";
import img9 from "../../Img/Mangesh/img1.webp";
import img10 from "../../Img/Mangesh/img2.png";
import img11 from "../../Img/Mangesh/img4.webp";
import img12 from "../../Img/Mangesh/img5.webp";
import img13 from "../../Img/Mangesh/img3.png";
import img14 from "../../Img/Mangesh/img7.png";
import img15 from "../../Img/Mangesh/img8.png";
import { Helmet } from "react-helmet";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ArtistContact from "../../components/artscomponents/ArtistContact";
import ArtSwiper from "./artscarousel/ArtSwiper";

const Mangesh_Nipanikar = (props) => {
  const [toggle, settoggle] = useState(false);

  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };
  const [selectedId, setSelectedId] = useState(1);

  const [fetcheddata, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.akkafoundation.in/api/arts/?artist_id=2&language_id=${
          props.toggle ? 2 : 1
        }`
      );
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      toast.error("Server offline!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.toggle]);
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <Helmet>
              <title>
                Mangesh Nipanikar - Renowned Artist from Nipani | Rangoli, Grass
                Art, Fire Painting, and More
              </title>
              <meta
                name="description"
                content="Explore the inspiring story of Mangesh Nipanikar from Nipani,
            Kalamb taluka. Overcoming adversity, he has achieved international
            acclaim with his grand rangoli, intricate grass art, fire painting,
            and oil painting. Discover his groundbreaking work with the Akka
            Foundation and his world record achievements."
              />
              <meta
                name="keywords"
                content="Mangesh Nipanikar, artist from Nipani, rangoli art, grass art, fire
            painting, oil painting, Shiva Jayanti, Akka Foundation, world records, artistic journey, international acclaim"
              />
              <link
                rel="canonical"
                href="https://akkafoundation.in/sustainableArt/Mangesh_Nipanikar"
              />
            </Helmet>

            <PageTop text={data.artist_name1} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.mangesh_intro}
              span={data.mangesh_span}
              title={data.mangesh_heading}
              ctatext={data.mangesh_cta}
            />
            <div className="mangesh parent">
              <div className="mangesh-container container-small">
                {/* {fetcheddata && fetcheddata.map((item, index) => (
                  <ArtImage
                    key={index}
                    open={open}
                    image={item.image}
                    onClick={() => setSelectedId(item.id)}
                  />
                ))} */}

                <ArtSwiper
                  data={fetcheddata}
                  open={open}
                  set={setSelectedId}
                  toggle={props.toggle}
                />
              </div>
            </div>
            {toggle && (
              <ArtDetail close={close} id={selectedId} data={props.data} />
            )}
          </>
        );
      })}
      <Toaster position="top-center" reverseOrder={false} />
      <ArtistContact toggle={props.toggle} />
    </>
  );
};

export default Mangesh_Nipanikar;
