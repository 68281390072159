import React, { useState } from "react";
import "../styles/gallery.css";
import PageTop from "../components/PageTop";
import Photo from "../components/Photo";
import { Helmet } from "react-helmet";
import Video from "../components/Video";
import Media from "../components/Media";
const Gallery = (props) => {
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <Helmet>
                <title>Discover Our Gallery: A Fusion of Images, Paper Cutouts, and Video Collections</title>
                <meta name="keywords" content=" gallery, media, paper cutouts, images, image collection, video collection, media collection " />
                <meta name="description" content="Explore our diverse gallery featuring captivating images, creative paper cutouts, and a curated collection of videos. Immerse yourself in a rich media experience with our unique collection. " />
                <link rel="canonical" href="https://akkafoundation.in/gallery/" />
            </Helmet>
            <PageTop
              text={data.PT_gallery}
              url="https://images.unsplash.com/photo-1682686578601-e7851641d52c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <div className="gallery-buttons parent">
              <div className="gallery-btn-div container-small">
                <button
                  className={
                    props.photo
                      ? "btn-active gallery-btn1"
                      : "btn-unactive gallery-btn1"
                  }
                  onClick={() => {
                    props.setmedia(false);
                    props.setphoto(true);
                    props.setvideo(false);
                  }}
                >
                  Photos
                </button>
                <button
                  className={
                    props.video
                      ? "btn-active gallery-btn1"
                      : "btn-unactive gallery-btn1"
                  }
                  onClick={() => {
                    props.setmedia(false);
                    props.setphoto(false);
                    props.setvideo(true);
                  }}
                >
                  Videos
                </button>
                <button
                  className={
                    props.media
                      ? "btn-active gallery-btn1"
                      : "btn-unactive gallery-btn1"
                  }
                  onClick={() => {
                    props.setmedia(true);
                    props.setphoto(false);
                    props.setvideo(false);
                  }}
                >
                  Media
                </button>
              </div>
            </div>
            <div className="gallery-container parent">
              {props.photo && <Photo />}
              {props.video && <Video />}
              {props.media && <Media />}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Gallery;
