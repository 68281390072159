import React from 'react'
import "../styles/smallblogcard.css"
import { Link } from 'react-router-dom'
const SmallBlogCard = (props) => {
    return (
        <Link to={props.path} className='smallcard-container'>
                <div className="left-img bg-img" style={{background : `url(${props.url})`}}></div>

            <div className="smallcard-right">
                <p className='category'>{props.cat}</p>
                <h3>{props.title.length > 65 ? props.title.slice(0, 65) + '...' : props.title}</h3>

                <p className='published'>pulished on <span>{props.date}</span></p>
            </div>
        </Link>
    )
}

export default SmallBlogCard