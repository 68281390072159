import React from "react";
import PageTop from "../components/PageTop";
import "../styles/drushti.css";
import { Link } from "react-router-dom";
import { Tilt } from "react-tilt";
import { Helmet } from "react-helmet";
import drushtimimg from "../Img/pt-da.webp"

function Drushti(props) {
  const defaultOptions = {
    reverse: false,
    max: 15,
    perspective: 1000,
    scale: 1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };

  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet>
            <title>Drushti Abhiyan: Akka Foundation's Vision for Clear Sight in Nilanga – Free Eye Checkups and Surgeries</title>
            <meta name="keywords" content="drushti abhiyan, eye health, akka foundation, nilanga, nilanga vidhan sabha, sambhajibhaiyya patil nilangekar, free eye checkups, free spectacles, free cataract surgeries" />
            <meta name="description" content="Drushti Abhiyan: Akka Foundation's Vision for Eye Health in Nilanga Vidhan Sabha, Providing Free Eye Checkups, Spectacles, and Cataract Surgeries, led by Sambhajibhaiyya Patil Nilangekar." />
            <link rel="canonical" href="https://akkafoundation.in/drushti"/>

          </Helmet>
            <PageTop
              text={data.DAM_pt_title}
              url={drushtimimg}
            />

            <div className="drushti-parent parent">
              <div className="drushti-container container-small">
               
                <div className="dbox-parent ">
                  <Tilt
                    options={defaultOptions}
                    className="dbox1 dimg1-box bg-img shine"
                  >
                    <Link to="/drushti-1" className="doverlay">
                      <h3>{data.DAM_drushti_abhiyan1}</h3>
                    </Link>
                  </Tilt>
                  <Tilt
                    options={defaultOptions}
                    className="dbox1 dimg1-box second-img bg-img shine"
                  >
                    <Link to="/drushti-2" className="doverlay">
                      <h3>{data.DAM_drushti_abhiyan2}</h3>
                    </Link>
                  </Tilt>
              

                  <Tilt
                    options={defaultOptions}
                    className="dbox1 dimg1-box third-img bg-img shine"
                  >
                    {" "}
                    <Link to="/drushti-3" className="doverlay">
                      <h3>{data.DAM_drushti_abhiyan3}</h3>
                    </Link>{" "}
                  </Tilt>

                  <Tilt
                    options={defaultOptions}
                    className="dbox1 dimg1-box fourth-img bg-img shine"
                  >
                    {" "}
                    <Link to="/drushti-4" className="doverlay">
                      <h3>{data.DAM_drushti_abhiyan4}</h3>
                    </Link>{" "}
                  </Tilt>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Drushti;
