import React, { useEffect, useState } from "react";
import "../styles/drushtitemp.css";
import PageTop from "../components/PageTop";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import CountUp from "react-countup";
import HeroCard from "../components/HeroCard";
import drushtiimg from "../Img/gallery-page/media/drushti1.webp";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
const Drushti_1 = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  
  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
            <Helmet>
              <title>Drushti Abhiyan 1.0</title>
              <meta
                name="keywords"
                content="Drushti, drushti abhiyan, latur, nilanga, drushti abhiyan in latur, drushti abhiyan 1.0, health, eye care, eye health"
              />
              <meta
                name="description"
                content="Drushti Abhiyan 1.0 is an initiative focused on promoting eye health and providing essential eye care services in Latur and Nilanga."
              />
              <link
                rel="canonical"
                href="https://akkafoundation.in/drushti-1"
              />
            </Helmet>

            <PageTop text={data.DA_pt_title} url={drushtiimg} />
            <HeroCard
              align="row"
              url={drushtiimg}
              desc={data.DA_description}
              span=""
              title={data.DA_title}
            />

            <div className="counter-parent parent">
              <div className="counter-container small-container">
                <h2>
                  {data.DA_counter_title} <span>{data.DA_counter_span}</span>{" "}
                </h2>
                <div className="counter_wrapper">
                  <div className="Counter_top" ref={ref}>
                    {isVisible && (
                      <>
                        <div className="group1">
                          <CountUp end={40} duration={32} />
                          <p className="Counter_p">{data.DA_village}</p>
                          <span className="Counter_sufix">{} </span>
                        </div>
                        <div className="group2">
                          <CountUp end={11356} duration={32} />
                          <p className="Counter_p">{data.DA_eye_check}</p>
                          <span className="Counter_sufix">{} </span>
                        </div>
                        <div className="group3">
                          <CountUp end={3399} duration={32} />
                          <p className="Counter_p">{data.DA_spectacles}</p>
                          <span className="Counter_sufix">{} </span>
                        </div>
                        <div className="group4">
                          <CountUp end={720} duration={32} />
                          <p className="Counter_p">{data.DA_cataract}</p>
                          <span className="Counter_sufix">{} </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="drushti-slider parent">
              <div className="drushtislider-container container-small">
                <h2>
                  {" "}
                  {data.DA_featured_title} <span>{data.DA_featured_span}</span>
                </h2>
                <div className="slider-box">
                  <div className="carousel-container">
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      <SwiperSlide className="slide slideabout6"></SwiperSlide>
                      <SwiperSlide className="slide slideabout7"></SwiperSlide>
                      <SwiperSlide className="slide slideabout8"></SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default Drushti_1;
