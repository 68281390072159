import React, { useEffect, useState } from "react";
import PageTop from "../components/PageTop";
import HeroCard from "../components/HeroCard";
import AboutCarousel from "../components/AboutCarousel";
import { Helmet } from "react-helmet";
import "../styles/Education.css";
import BeforeFooter from "../components/BeforeFooter";
import SimpleCard from "../components/SimpleCard";
import educations from "../Img/education.webp";
import educations1 from "../Img/ourwork/ehc.webp";
import slidered1 from "../Img/ourwork/ed1.webp"
import slidered2 from "../Img/ourwork/ed2.webp"
import slidered3 from "../Img/ourwork/ed3.webp"
import slidered4 from "../Img/ourwork/ed4.webp"
import slidered5 from "../Img/ourwork/ed5.webp"

function Education(props) {
  const images = [slidered1, slidered2, slidered3, slidered4, slidered5];
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet>
            <title>Education Empowerment: Free Digital Classrooms, Career Workshops, Scholarships, and Financial Aid</title>
            <meta name="description" content="Empowering Education: Free digital classrooms, career workshops, scholarships, and financial aid. Join us in creating a brighter future together. " />
            <meta name="keywords" content="education, free education, digital classroom, career counseling workshop, scholarships, financial aid " />
            <link rel="canonicals" href="https://akkafoundation.in/education/" />
          </Helmet>
            <PageTop text={data.EP_pt_text} url={educations} />
            <HeroCard
              align="row"
              url={educations1}
              desc={data.EP_hc_desc}
              span={data.EP_hc_span}
              title={data.EP_hc_title}
            />

            {/* here starts the independent section */}

            {/* here ends the independent section */}

            <div className="education-independent parent" index={""}>
              <div className="container-small education-independent-container">
                <SimpleCard
                  title={data.EP_is_card1_title}
                  span={data.EP_is_card1_span}
                  desc={data.EP_is_card1_desc}
                />

                <SimpleCard
                  title={data.EP_is_card2_title}
                  span={data.EP_is_card2_span}
                  desc={data.EP_is_card2_desc}
                />
                <SimpleCard
                  title={data.EP_is_card3_title}
                  span={data.EP_is_card3_span}
                  desc={data.EP_is_card3_desc}
                />
                <SimpleCard
                  title={data.EP_is_card4_title}
                  span={data.EP_is_card4_span}
                  desc={data.EP_is_card4_desc}
                />
              </div>
            </div>

            <div className="education-slider parent">
              <div className="education-slider-container container-small">
                <h2>
                  {data.EP_fi_title} <span> {data.EP_fi_span} </span>
                </h2>
                <div className="slider-box">
                  <AboutCarousel images={images} />
                </div>
              </div>
            </div>

            <BeforeFooter data={props.data} />
          </>
        );
      })}
    </>
  );
}

export default Education;
