import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import "../styles/homecarousel.css"
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6"
const HomeCarousel = (props) => {
    
    return (
        <>
        {props.data.map((data)=>{return( 
            <>
        <div className='carousel-container-home'>
            <Swiper
                   pagination={{
                    clickable: true,
                  }}        autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }} modules={[Autoplay,Pagination]} className="mySwiper">

                <SwiperSlide className='slide slideh1 parent'>
                    <div className="overlay"></div>
                    <div className="slide-inner-container container-large">
                        <div className="slide-content-box">
                            <h1>{data.HP_hs_s1_h}</h1>
                            <p>{data.HP_hs_s1_desc}</p>
                            {/* <Link className='btn-active'>Donate Now <span><FaArrowRight /></span></Link> */}
                        </div>
                    </div>
                </SwiperSlide >
                <SwiperSlide className='slide slideh2 parent'>
                    <div className="overlay"></div>
                    <div className="slide-inner-container container-large">
                        <div className="slide-content-box">
                            <h1>{data.HP_hs_s2_h}</h1>
                            <p>{data.HP_hs_s2_desc}</p>
                            {/* <Link className='btn-active'>Donate Now <span><FaArrowRight /></span></Link> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide slideh3 parent'>
                    <div className="overlay"></div>
                    <div className="slide-inner-container container-large">
                        <div className="slide-content-box">
                            <h1>{data.HP_hs_s3_h}</h1>
                            <p>{data.HP_hs_s3_desc}</p>
                            {/* <Link className='btn-active'>Donate Now <span><FaArrowRight /></span></Link> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide slideh4 parent'>
                    <div className="overlay"></div>
                    <div className="slide-inner-container container-large">
                        <div className="slide-content-box">
                            <h1>{data.HP_hs_s4_h}</h1>
                            <p> {data.HP_hs_s4_desc} </p>
                            {/* <Link className='btn-active'>Donate Now <span><FaArrowRight /></span></Link> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide slideh5 parent'>
                    <div className="overlay"></div>
                    <div className="slide-inner-container container-large">
                        <div className="slide-content-box">
                            <h1>{data.HP_hs_s5_h}</h1>
                            <p>{data.HP_hs_s5_desc}</p>
                            {/* <Link className='btn-active'>Donate Now <span><FaArrowRight /></span></Link> */}
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        </>
        )})}
        </>
    )
}

export default HomeCarousel