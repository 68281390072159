import React, { useState } from "react";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ComplexCard from "../../components/ComplexCard";
import HeroCard from "../../components/HeroCard";
import PageTop from "../../components/PageTop";
import artisttwo from "../../Img/artist/Divyashri.png";
import artistone from "../../Img/artist/Mangesh.png";
import artistthree from "../../Img/artist/Ravishankar.png";
import secondimg from "../../Img/divyashri/img3.jpeg";
import "./sustainableart.css";
const SustainableArt = (props) => {
  const [toggle, settoggle] = useState(false);
  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <PageTop text={data.sustainable_mangesh_title} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.sustainable_mangesh_intro}
              span={data.AP_oi_span}
              title={data.sustainable_mangesh_sub_title}
            />
            {/* New card for 3 person */}
            <div className="founder parent">
              <div className="founder-container container-small">
                <h2>
                  <span>{data.artist}</span>
                  {data.arttext}
                  <span>{data.artist1}</span>
                </h2>
                <div className="founder-cards">
                  <ComplexCard
                    url={artistone}
                    title={data.artist_name1}
                    desc={data.artist_desc1}
                    btntext={data.AP_btn_box}
                    href="./SustainableArt/Mangesh_Nipanikar"
                  />
                  
                  <ComplexCard
                    url={artisttwo}
                    title={data.artist_name2}
                    desc={data.artist_desc2}
                    btntext={data.AP_btn_box}
                    href="./SustainableArt/Divyashri_and_Vyankatesh"
                  />
                  <ComplexCard
                    url={artistthree}
                    title={data.artist_name3}
                    desc={data.artist_desc3}
                    btntext={data.AP_btn_box}
                    href="./SustainableArt/Ravishankar_Barskar"
                  />
                </div>
              </div>
            </div>

            {toggle && <ArtDetail close={close} />}
          </>
        );
      })}
    </>
  );
};

export default SustainableArt;
