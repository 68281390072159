import React, { useEffect } from "react";
import "../styles/media.css";
import ReactPlayer from 'react-player'

import { Link } from "react-router-dom";
import img from  '../Img/gallery-page/media/akka-foundation-media.jpeg'
import img1 from  '../Img/gallery-page/media/akka-foundation-media1.jpeg'
import img2 from  '../Img/gallery-page/media/akka-foundation-media2.jpeg'
import img3 from  '../Img/gallery-page/media/akka-foundation-media3.jpeg'
import img4 from  '../Img/gallery-page/media/akka-foundation-media4.jpeg'
import img5 from  '../Img/gallery-page/media/akka-foundation-media5.jpeg'
import img6 from  '../Img/gallery-page/media/akka-foundation-media6.jpeg'
import img7 from  '../Img/gallery-page/media/akka-foundation-media7.jpeg'
import img8 from  '../Img/gallery-page/media/akka-foundation-media8.jpeg'
import img9 from '../Img/gallery-page/media/akka-foundation-media9.jpeg' 
import img10 from '../Img/gallery-page/media/akka-foundation-media10.jpeg' 
import img11 from '../Img/gallery-page/media/akka-foundation-media11.jpeg' 
import img12 from '../Img/gallery-page/media/akka-foundation-media12.jpeg'
import img13 from '../Img/gallery-page/media/akka-foundation-media13.jpg'
import img14 from '../Img/gallery-page/media/akka-foundation-media14.jpg'
import img15 from '../Img/gallery-page/media/akka-foundation-media15.jpg'
import img16 from '../Img/gallery-page/media/akka-foundation-media16.jpg'
import img17 from '../Img/gallery-page/media/akka-foundation-media17.jpg'
import img18 from '../Img/gallery-page/media/akka-foundation-media18.jpg'
import img19 from '../Img/gallery-page/media/akka-foundation-media19.jpg'
import img20 from '../Img/gallery-page/media/akka-foundation-media20.jpg'
import img21 from '../Img/gallery-page/media/akka-foundation-media21.jpg'
import img22 from '../Img/gallery-page/media/akka-foundation-media22.jpg'
import img23 from '../Img/gallery-page/media/akka-foundation-media23.jpg'
import img24 from '../Img/gallery-page/media/akka-foundation-media24.jpg'
import img25 from '../Img/gallery-page/media/akka-foundation-media25.jpg'
import img26 from '../Img/gallery-page/media/akka-foundation-media26.jpg'
import img27 from '../Img/gallery-page/media/akka-foundation-media27.jpg'
import img28 from '../Img/gallery-page/media/akka-foundation-media28.jpg'
import img29 from '../Img/gallery-page/media/akka-foundation-media29.jpg'
import img30 from '../Img/gallery-page/media/akka-foundation-media30.jpg'
import img31 from '../Img/gallery-page/media/akka-foundation-media31.jpg'
import img32 from '../Img/gallery-page/media/akka-foundation-media32.jpg'
import img33 from '../Img/gallery-page/media/akka-foundation-media33.jpg'
import img34 from '../Img/gallery-page/media/akka-foundation-media34.jpg'
import img35 from '../Img/gallery-page/media/akka-foundation-media35.jpg'
import img36 from '../Img/gallery-page/media/akka-foundation-media36.jpg'
import img37 from '../Img/gallery-page/media/akka-foundation-media37.jpg'
import img38 from '../Img/gallery-page/media/akka-foundation-media38.jpg'
import img39 from '../Img/gallery-page/media/akka-foundation-media39.jpg'
import img40 from '../Img/gallery-page/media/akka-foundation-media40.jpg'
import img41 from '../Img/gallery-page/media/akka-foundation-media41.jpg'
import img42 from '../Img/gallery-page/media/akka-foundation-media42.jpg'
import img43 from '../Img/gallery-page/media/akka-foundation-media43.jpg'
import img44 from '../Img/gallery-page/media/akka-foundation-media44.jpg'
import img45 from '../Img/gallery-page/media/akka-foundation-media45.jpg'
import img46 from '../Img/gallery-page/media/akka-foundation-media46.jpg'
import img47 from '../Img/gallery-page/media/akka-foundation-media47.jpg'
import img48 from '../Img/gallery-page/media/akka-foundation-media48.jpg'
import img49 from '../Img/gallery-page/media/akka-foundation-media49.jpg'
import img50 from '../Img/gallery-page/media/akka-foundation-media50.jpg'
import img51 from '../Img/gallery-page/media/akka-foundation-media51.jpg'
import img52 from '../Img/gallery-page/media/akka-foundation-media52.jpg'
import img53 from '../Img/gallery-page/media/akka-foundation-media53.jpg'
import img54 from '../Img/gallery-page/media/akka-foundation-media54.jpg'
import img55 from '../Img/gallery-page/media/akka-foundation-media55.jpg'
import img56 from '../Img/gallery-page/media/akka-foundation-media56.jpg'
import img57 from '../Img/gallery-page/media/akka-foundation-media57.jpg'
import img58 from '../Img/gallery-page/media/akka-foundation-media58.jpg'
import img59 from '../Img/gallery-page/media/akka-foundation-media59.jpg'
import img60 from '../Img/gallery-page/media/akka-foundation-media60.jpg'
import img61 from '../Img/gallery-page/media/akka-foundation-media61.jpg'
import img62 from '../Img/gallery-page/media/akka-foundation-media62.jpg'
import img63 from '../Img/gallery-page/media/akka-foundation-media63.jpg'
import img64 from '../Img/gallery-page/media/akka-foundation-media64.jpg'
import img65 from '../Img/gallery-page/media/akka-foundation-media65.jpg'
import img66 from '../Img/gallery-page/media/akka-foundation-media66.jpg'
import img67 from '../Img/gallery-page/media/akka-foundation-media67.jpg'
import img68 from '../Img/gallery-page/media/akka-foundation-media68.jpg'
import img69 from '../Img/gallery-page/media/project-anandi-media1.jpeg'
import img70 from '../Img/gallery-page/media/project-anandi-media2.jpeg'
import img71 from '../Img/gallery-page/media/project-anandi-media3.jpeg'
import img72 from '../Img/gallery-page/media/project-anandi-media4.jpeg'
import img73 from '../Img/gallery-page/media/project-anandi-media5.jpeg'
import img74 from '../Img/gallery-page/media/project-anandi-media6.jpeg'
import img75 from '../Img/gallery-page/media/project-anandi-media7.jpeg'
import img76 from '../Img/gallery-page/media/project-anandi-media8.jpeg'
import img77 from '../Img/gallery-page/media/project-anandi-media9.jpeg'
import img78 from '../Img/gallery-page/media/project-anandi-media10.jpeg'
import img79 from '../Img/gallery-page/media/Drushi-abhiyan-media1.jpg'
import img80 from '../Img/gallery-page/media/Drushti-abhiyan-media2.jpg'
import img81 from '../Img/gallery-page/media/Drushti-abhiyan-media3.jpg'
import img82 from '../Img/gallery-page/media/Drushti-abhiyan-media4.jpg'
import img83 from '../Img/gallery-page/media/Drushti-abhiyan-media5.jpg'
import img84 from '../Img/gallery-page/media/Drushti-abhiyan-media6.jpg'
const { useState } = React;



//IMAGE ARRAY
const images = [img, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42, img43, img44, img45, img46, img47, img48, img49, img50, img51, img52, img53, img54, img55, img56, img57, img58, img59, img60, img61, img62, img63, img64, img65, img66, img67, img68, img69, img70, img71, img72, img73, img74, img75, img76, img77, img78, img79, img80, img81, img82, img83, img84
    
];


//MAIN APP COMPONENT
const Media=()=> {
    return (
        <>
            <div className="media-btns-parent">
                
          


            <div className="media-parent">
                <div className="media-gallery-c">
                    <ImageGallery />
                </div>
            </div>

    
            </div>
        </>

    );
}

function ImageGallery() {
    const [imageToShow, setImageToShow] = useState("");
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);
    const [visible, setvisible] = useState(6)
    const loadmoreHandler =()=>
    {
        setvisible((prevvalue)=>prevvalue+6)
    }
    //looping through our images array to create img elements
    const imageCards = images.slice(0, visible).map((image) => (
        <img key={image} className="image-card" onClick={() => showImage(image)} src={image} alt={image} />
    ));

    //function to show a specific image in the lightbox, amd make lightbox visible
    const showImage = (image) => {
        setImageToShow(image);
        setLightBoxDisplay(true);
    };

    //hide lightbox
    const hideLightBox = () => {
        setLightBoxDisplay(false);
    };

    //show next image in lightbox
    const showNext = (e) => {
        e.stopPropagation();
        let currentIndex = images.indexOf(imageToShow);
        if (currentIndex >= images.length - 1) {
            setLightBoxDisplay(false);
        } else {
            let nextImage = images[currentIndex + 1];
            setImageToShow(nextImage);
        }
    };

    //show previous image in lightbox
    const showPrev = (e) => {
        e.stopPropagation();
        let currentIndex = images.indexOf(imageToShow);
        if (currentIndex <= 0) {
            setLightBoxDisplay(false);
        } else {
            let nextImage = images[currentIndex - 1];
            setImageToShow(nextImage);
        }
    };
    const showLoadMoreButton = images.length > visible;
    return (
        <>
            <div>{imageCards}</div>
            {
                lightboxDisplay ?
                    <div id="lightbox" onClick={hideLightBox}>
                        <button className="gallery-btn" onClick={showPrev}>⭠</button>
                        <img id="lightbox-img" src={imageToShow} alt="imageToShow" />
                        <button className="gallery-btn" onClick={showNext}>⭢</button>
                    </div>
                    : ""
            }
          {showLoadMoreButton && <button className="btn-active loadmore-btn" onClick={loadmoreHandler}>Load More</button>}  
        </>
    )
}


export default Media