import React, { useEffect, useState } from "react";
import { CiCalendarDate } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import "./artdetail.scss";
// mangesh images
//Ravishankar
import axios from "axios";
const ArtDetail = ({ close, id, data }) => {
  console.log(data);

  const [fetchedData, setFetchedData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.akkafoundation.in/api/arts/${id}`);
      console.log(response.data);
      setFetchedData(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div className="art-detail-parent">
      <div className="art-detail-box">
        <button className="back-button" onClick={close}>
          <RxCross1 />
        </button>
        <div className="art-detail-title">
          {fetchedData && fetchedData.image_title}
        </div>
        <div
          className="art-detail-img bg-img"
          style={{ background: `url(${fetchedData && fetchedData.image})` }}
        ></div>
        <div className="art-detail-header">
          <div className="location btn-new-design">
            <span>
              <IoLocationOutline />
            </span>{" "}
            {fetchedData && fetchedData.location}
          </div>
          <div className="date btn-new-design">
            <span>
              <CiCalendarDate />
            </span>
            {fetchedData && fetchedData.date}
          </div>
        </div>
        <div className="art-detail-heading2">
          {fetchedData && fetchedData.image_subtitle}
        </div>
        <div className="art-detail-content">
          {fetchedData && fetchedData.image_description}
        </div>
      </div>
    </div>
  );
};

export default ArtDetail;
