import React from 'react'
import "../styles/hooksection.css"
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa6";
const HookSection = (props) => {
  return (
    <div className="hook-parent parent" style={{background: `${props.bgcolor}`}}>
        <div className="hook-container container-small">
            <h2>{props.text}</h2>
            <Link className='btn-active' to={props.link}> {props.btn_text}  <FaArrowRight /></Link>
           </div>
    </div>
  )
}
export default HookSection