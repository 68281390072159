import React from "react";
import HeroCard from "../components/HeroCard";
import PageTop from "../components/PageTop";
import "../styles/health.css";
import SimpleCard from "../components/SimpleCard";
import Ourworkcarousel from "../components/Ourworkcarousel";
import BeforeFooter from "../components/BeforeFooter";
import AboutCarousel from "../components/AboutCarousel";
import health1 from '../Img/ourwork/h1.webp'
import health2 from '../Img/ourwork/h2.webp'
import { Helmet } from "react-helmet";
import health3 from '../Img/ourwork/h3.webp'
import health4 from '../Img/ourwork/h4.webp'
import health5 from '../Img/ourwork/h5.webp'
import health6 from '../Img/ourwork/h6.webp'
import health7 from '../Img/ourwork/h7.webp'
import healthtp from "../Img/Health.webp"
import healthhero from "../Img/ourwork/hhc-1.webp"

function Health(props) {
  const images=[
    health1,
    health2,
    health3,
    health4,
    health5,
    health6,
    health7,

  ];
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet>
            <title>Akka Foundation: Nurturing Health with Routine Check-ups, Emergency Services, and COVID-19 Initiatives</title>
            <meta name="keywords" content="akkafoundation, healthcare resources, routine check-ups, emergency services, awarness campaigns, blood donation, maha aarogya camp, corona battle, covid-19 crisis, corona era, immunisation taskforce, vacination, vacination task force "/>
            <meta name="description" content="Explore Akka Foundation's healthcare initiatives, including routine check-ups, emergency services, and awareness campaigns. Join us in the battle against COVID-19 through initiatives like blood donation and vaccination drives. " />
            <link rel="canonical" href="https://akkafoundation.in/health/" />
          </Helmet>
            <PageTop text={data.HEP_pt_title} url={healthtp}/>

            <HeroCard
              align="row"
              url={healthhero}
              desc={data.HEP_hc_desc}
              span={data.HEP_hc_span}
              title={data.HEP_hc_title}
            />

            <div className="health-independent parent">
              <div className="health-independent-container container-small">
                <SimpleCard
                  title={data.HEP_is1_card1_title}
                  desc={data.HEP_is1_card1_desc}
                  
                />
                <SimpleCard
                  title={data.HEP_is1_card2_title}
                  desc={data.HEP_is1_card2_desc}
                  
                />
                <SimpleCard
                  title={data.HEP_is1_card3_title}
                  desc={data.HEP_is1_card3_desc}
                  
                />
                
              </div>
            </div>

            <div className="health-independent2 parent">
              <div className="health-independent2-container container-small">
                <div className="health2-left card-list">
                  <h3>{data.HEP_is2_card1_title}</h3>

                  <ul>
                    <li>{data.HEP_is2_card1_li1}</li>
                    <li>{data.HEP_is2_card1_li2}</li>
                    <li>{data.HEP_is2_card1_li3}</li>
                    <li>{data.HEP_is2_card1_li4}</li>
                    <li>{data.HEP_is2_card1_li5}</li>
                    <li>{data.HEP_is2_card1_li6}</li>
                    <li>{data.HEP_is2_card1_li7}</li>
                    
                  </ul>
                </div>
                <div className="health2-right card-list">
                <h3>{data.HEP_is2_card2_title} <span>  {data.HEP_is2_card2_span}  </span> </h3>

                  <p>{data.HEP_is2_card2_desc}</p>
                  <p>{data.HEP_is2_card2_desc2}</p>
                  
                </div>
              </div>
            </div>
            <div className="health-carousel parent">
              <div className="health-carousel-container container-small">
                <h2>{data.HEP_fi_title}</h2>

                <div className="carousel-box">
                  <AboutCarousel images={images}/>
                </div>
              </div>
            </div>
            <BeforeFooter bgcolor="var(--baiege)" data={props.data}/>
          </>
        );
      })}
    </>
  );
}
export default Health;
