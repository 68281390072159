import React, { useState } from "react";
import "../styles/contact.css";
import PageTop from "../components/PageTop";
import { MdPhone } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import contacttp from "../Img/contact.webp";
import { FaArrowRight } from "react-icons/fa6";
const Contact = (props) => {
  const [data, setdata] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const date = currentDate.toLocaleDateString("en-IN", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const time = currentDate.toLocaleTimeString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    const newData = {
      ...data,
      filled_date: date,
      filled_time: time,
    };
    if (data.name != "" && data.email != "" && data.contact != "") {
      axios
        .post(`${props.url}/contacts/`, newData)
        .then(function (response) {
          toast.success("Your message has been submitted.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setdata({
            name: "",
            email: "",
            contact: "",
            message: "",
          });
        })
        .catch(function (error) {});
    } else {
      toast.error("Required fields must be filled", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>Contact Akka Foundation- A non profit organization. </title>
        <meta
          name="keywords"
          content="contact akkafoundation, query for akkafoundation, enquiry for akkafoundation"
        />
        <meta
          name="description"
          content="Contact us for queries related to akka foundation, it is non profit organization. It helps for women empowerment, environment etc."
        />
        <link rel="canonical" href="https://akkafoundation.in/contact" />
      </Helmet>
      <PageTop text="Contact" url={contacttp} />
      <div className="contact-parent parent">
        <div className="contact-container container-small">
          <div className="contact-left">
            <form action="#" onSubmit={submitHandler}>
              <input
                type="text"
                placeholder="Name *"
                value={data.name}
                required
                onChange={(e) => setdata({ ...data, name: e.target.value })}
              />
              <div className="input-row">
                <input
                  type="email"
                  placeholder="Email *"
                  value={data.email}
                  required
                  onChange={(e) => setdata({ ...data, email: e.target.value })}
                />
                <input
                  type="number"
                  placeholder="Phone *"
                  value={data.contact}
                  required
                  onChange={(e) =>
                    setdata({ ...data, contact: e.target.value })
                  }
                />
              </div>
              <textarea
                name=""
                id=""
                cols="5"
                rows="5"
                value={data.message}
                placeholder="Your Message"
                onChange={(e) => setdata({ ...data, message: e.target.value })}
              ></textarea>
              <button type="submit" className="btn-active">
                {" "}
                Submit{" "}
                <span>
                  {" "}
                  <FaArrowRight />
                </span>{" "}
              </button>
            </form>
          </div>
          <div className="contact-right">
            <div className="contact-call contact-right-div">
              <span className="">
                <MdPhone />
              </span>
              +91 7666601972
            </div>
            <div className="contact-location contact-right-div">
              <span className="">
                <AiOutlineMail />
              </span>{" "}
              info@akkafoundation.in
              <br />
              contact@akkafoundation.in{" "}
            </div>
            <div className="contact-mail contact-right-div">
              <span className="">
                <HiOutlineLocationMarker />
              </span>
              Office no.214-216, 10 Biz Park, Near Symbiosis Law College,
              Airport Road, H.No.1-1/7 Ajanta Twakij, Nilanga -413521
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Contact;
