import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FaArrowRight } from "react-icons/fa6";

import "../styles/janjagar--slider.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import SimpleCard from "./SimpleCard";
import img1 from "../Img/janjagar/6.webp";
import img2 from "../Img/janjagar/ajitpatil.jpg";
import img3 from "../Img/janjagar/deshmukh.jpg";
import img4 from "../Img/janjagar/ravindra-pathak.jpg";
import img5 from "../Img/janjagar/dr-uday.jpg";
import img6 from "../Img/janjagar/prerana-honrao.jpg";
import img7 from "../Img/janjagar/ramling-shere.jpg";
import img8 from "../Img/janjagar/waghmare.jpg";
import img9 from "../Img/janjagar/shailesh_gojamgunde.jpg";
function JanjagarSlider(props) {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 4500000000,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img1})` }}
                  ></div>
                </div>


                <div className="card-list">
                  <h3>{data.j_j_text1}</h3>
                  <p>{data.j_j_desc1}</p>

                  <a class="btn-active" href="https://www.youtube.com/watch?v=HG0j3AWEjhs&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=6" target="_blank" > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>

                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img2})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text2}</h3>
                  <p>{data.j_j_desc2}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=QSiks_7KL_M&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=4" target="_blank" > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img3})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text3}</h3>
                  <p>{data.j_j_desc3}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=Wcld-ojBWSM" target="_blank" > View more <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img4})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text4}</h3>
                  <p>{data.j_j_desc4}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=XkM1NM-qHiE&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=5" target="_blank" > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img5})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text5}</h3>
                  <p>{data.j_j_desc5}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=WK5ihGvK0kc&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=7" target="_blank" > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img6})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text6}</h3>
                  <p>{data.j_j_desc6}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=HpyKzhLeW-Y&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=8" target="_blank" > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img7})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text7}</h3>
                  <p>{data.j_j_desc7}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=gT8J1blwp04" target="_blank" >View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img8})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text8}</h3>
                  <p>{data.j_j_desc8}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=4zbytqFEkjI&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=9" target="_blank"  > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <div
                    className="img bg-img"
                    style={{ background: `url(${img9})` }}
                  ></div>
                </div>
                <div className="card-list">
                  <h3>{data.j_j_text9}</h3>
                  <p>{data.j_j_desc9}</p>
                  <a class="btn-active" href="https://www.youtube.com/watch?v=P4W4J0Wk7Ig&list=PLY4MBhBwYwdNwPJusCEAK8H4_692BTUtH&index=2" target="_blank" > View On Youtube <span>
                    <FaArrowRight />
                  </span></a>
                </div>
              </SwiperSlide>
            </Swiper>

          </>
        );
      })}
    </>
  );
}

export default JanjagarSlider;
