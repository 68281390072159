import React from "react";
import "../styles/beforefooter.css";
import { Link } from "react-router-dom";
const BeforeFooter = (props) => {
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div
              className="beforefooter parent"
              style={{
                background: `${props.bgcolor}`,
              }}
            >
              <div className="beforefooter-container container-small">
                <h2>
                  {data.bf_tilte}
                  <span>{data.bf_title_span}</span>
                </h2>

                <div className="big-button-box">
                  <Link to="/donate"className="big-button bigbutton1">
                    <h4> {data.bf_donate}</h4>
                  </Link>
                  <Link to="/contact" target className="big-button bigbutton2">
                    <h4> {data.bf_contact}</h4>
                  </Link>
                  <Link to="/volunteer" className="big-button bigbutton3">
                    <h4>{data.bf_volunteer}</h4>
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default BeforeFooter;
