import React, { useEffect, useState } from "react";
import PageTop from "../components/PageTop";
import HeroCard from "../components/HeroCard";
import CountUp from "react-countup";
import projectanimg from "../Img/pt-ab.webp"
import { useInView } from "react-intersection-observer";
import AboutCarousel from "../components/AboutCarousel";
import img1 from "../extra/_MG_9840.webp"
import img2 from "../extra/345594202_947547596587389_3448222367530300439_n.webp"
import img3 from "../extra/_MG_1762.webp"
import img4 from "../extra/_MG_1762.webp"
import { Helmet } from "react-helmet";
import img5 from "../extra/_MG_1683.webp"
import img6 from "../extra/_MG_1578.webp"
const ProjectAnandi = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const images = [img1,img2,img3,img4,img5, img6];


  useEffect(() => {
    if (inView) {
      setIsVisible(true);
 
    }
  }, [inView]);
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
          <Helmet>
            <title>Project Anandi: Empowering Rural Women and girls for Menstrual Health and Self-Employment </title>
            <meta name="keywords" content="akka, akkafoundation, project anandi, anandi, menstrual health problems, free sanitary pads, self-employment to rual woman" />
            <meta name="description" content=" "/>
            <link rel="canonical" href="https://akkafoundation.in/project-Anandi" />
          </Helmet>
            <PageTop text={data.PA_main_title} url={projectanimg}/>
            <HeroCard
              align="row"
              url={projectanimg}
              desc={data.PA_sdisc}
              span={data.Pa_spans}
              title={data.Pa_titles}
            />
             <div className="counter-parent parent">
        <div className="counter-container small-container">
          <h2>
            {data.counter_title}  <span> {data.counter_span}</span>{" "}
          </h2>
          <div className="counter_wrapper">
            <div className="Counter_top" ref={ref}>
              {isVisible && <>
              <div className="group1">
                <CountUp end={342} duration={5} />
                <p className="Counter_p">{data.counter_stat_1}</p>
                <span className="Counter_sufix">{} </span>
              </div>
              <div className="group2">
                <CountUp end={27655} duration={5} />
                <p className="Counter_p">{data.counter_stat_2}</p>
                <span className="Counter_sufix">{} </span>
              </div>
              <div className="group3">
                <CountUp end={179} duration={5} />
                <p className="Counter_p">{data.counter_stat_3}</p>
                <span className="Counter_sufix">{} </span>
              </div>
              <div className="group4">
                <CountUp end={257} duration={5} />
                <p className="Counter_p">{data.counter_stat_4}</p>
                <span className="Counter_sufix">{} </span>
              </div> 
              </>}
            </div>
          </div>
        </div>
      </div>
      <div className="empower-carousel parent"  style={{background: "var(--baiege)"}}>
              <div className="empower-carousel-container container-small">
                

                <div className="carousel-box">
                  <AboutCarousel images={images} />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default ProjectAnandi;
