import React, { useEffect, useState } from "react";
import "../styles/progresscard.css";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa6";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
const ProgressCard = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
      if (inView1) {
        setIsVisible1(true);
      }
    }
  }, [inView, inView1]);
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <div className="progresscard-wrapper parent" key={index}>
            <div className="progress-card-container container-small">
              <h2>
                {data.HP_cs_heading}
                <span> {data.HP_cs_heading_span}</span>
              </h2>
              <div className="stats-container">
                <div className="stats-top">
                  <div className="stats-image1 bg-img"></div>
                  <div className="stats-cards">
                    <h3>{data.HP_cs_pa_title}</h3>
                    <p>{data.HP_cs_pa_desc}</p>
                    <div className="progress-bar">
                      <div className="progress-bg">
                        <div
                          className="progress-line"
                          style={{ width: "15%" }}
                        ></div>
                        <div
                          className="progress-dot"
                          progress="15%"
                          style={{ left: "15%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="stats-numbers">
                      <div className="stats-current">
                        <span className="stats-indicator" ref={ref}>
                          {data.HP_cs_pa_current}
                        </span>
                        {isVisible && (
                          <span className="stats-values">
                            <CountUp end={19954} />
                          </span>
                        )}
                      </div>
                      <div className="stats-arrow">
                        <BsArrowRight />
                      </div>
                      <div className="stats-target">
                        <span className="stats-indicator">
                          {data.HP_cs_pa_target}
                        </span>
                        {isVisible && (
                          <span className="stats-values">
                            <CountUp end={140000} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="stats-buttons">
                      <Link to="/donate" className="btn-active">
                        {data.HP_cs_pa_cta}{" "}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                      <Link to="/project-Anandi" className="btn2">{data.HP_cs_pa_learn}</Link>
                    </div>
                  </div>
                </div>
                <div className="stats-bottom">
                  <div className="stats-image2 paimg bg-img"></div>
                  <div className="stats-cards">
                    <h3>{data.HP_cs_da_title}</h3>
                    <p>{data.HP_cs_da_desc}</p>
                    <div className="progress-bar">
                      <div className="progress-bg">
                        <div
                          className="progress-line"
                          style={{ width: "49%" }}
                        ></div>
                        <div
                          className="progress-dot"
                          progress="49%"
                          style={{ left: "49%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="stats-numbers">
                      <div className="stats-current">
                        <span className="stats-indicator" ref={ref1}>
                          {data.HP_cs_da_current}
                        </span>
                        {isVisible1 && (
                          <span className="stats-values">
                            <CountUp end={24648} />
                          </span>
                        )}
                      </div>
                      <div className="stats-arrow">
                        <BsArrowRight />
                      </div>
                      <div className="stats-target">
                        <span className="stats-indicator">
                          {data.HP_cs_da_target}
                        </span>
                        {isVisible1 && (
                          <span className="stats-values">
                            <CountUp end={50000} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="stats-buttons">
                      <Link to="/donate" className="btn-active">
                        {data.HP_cs_da_cta}
                        <span>
                          <FaArrowRight />
                        </span>
                      </Link>
                      <Link to="/drushti" className="btn2">{data.HP_cs_da_learn}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProgressCard;
