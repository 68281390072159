import React from "react";
import "../styles/privacy.css";

function Privacy() {
  return (
    <>
      <div className="privacy-parent">
        <div className="privacy">
          <h1>Privacy Policy</h1>

          <h3>Who We Are</h3>
          <p>
            Our website address is:{" "}
            <a href="https://akkafoundation.in">https://akkafoundation.in</a>.
            We are a non-profit organization focused on community welfare and
            development.
          </p>

          <h3>Data We Collect</h3>
          <p>
            When you make a donation through our website, we collect certain
            personal data such as your name, email address, and payment
            information. This data is necessary to process your donation and
            issue receipts.
          </p>
          <p>
            We also collect information through our contact form, including your
            name, email address, and any message you provide. This data is used
            solely to respond to your inquiries.
          </p>

          <h3>How We Use Your Data</h3>
          <p>
            The data we collect is used to process donations, communicate with
            donors, and respond to inquiries submitted through our contact form.
            We do not share your personal data with third parties, except as
            required by law.
          </p>

          <h3>Cookies</h3>
          <p>
            Our website may use cookies to improve your browsing experience.
            These cookies do not collect personal information and are used
            solely for website functionality and analytics purposes.
          </p>

          <h3>Your Data Rights</h3>
          <p>
            If you have made a donation or contacted us through the website, you
            can request to receive a copy of the personal data we hold about
            you. You can also request that we erase any personal data we hold
            about you, except for data we are required to keep for
            administrative, legal, or security purposes.
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a href="mailto:support@akkafoundation.in">
              support@akkafoundation.in
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}

export default Privacy;
