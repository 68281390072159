import React from "react";
import "../styles/pagenot.css";
import { Link } from "react-router-dom";
import PageTop from "../components/PageTop";
const NotFound = (props) => {
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
            <PageTop text={data.PNF_pt_title} />

            <div className="pagenot parent">
              <div className="pagenot-container container-small">
                <div className="oops"></div>
                <Link className="btn-active error-btn" to="/">
                  {data.PNF_cta}
                </Link>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default NotFound;
