import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ArtImage from "../../components/artscomponents/ArtImage";
import ArtistContact from "../../components/artscomponents/ArtistContact";
import HeroCard from "../../components/HeroCard";
import PageTop from "../../components/PageTop";
import secondimg from "../../Img/sheelatai.webp";
import "./sustainableart.css";
import ArtSwiper from "./artscarousel/ArtSwiper";
import { Helmet } from "react-helmet";
const Shilatai = (props) => {
  const [toggle, settoggle] = useState(false);
  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };
  // const images = [
  //   img1,img2,img3,img4,img5,img6,img7,img8,img9, img10,img11,img12,img13
  // ];

  const [selectedId, setSelectedId] = useState(1);

  const [fetcheddata, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.akkafoundation.in/api/arts/?artist_id=4&language_id=${
          props.toggle ? 2 : 1
        }`
      );
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      toast.error("Server offline!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.toggle]);
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <Helmet>
              <title>
              Shilatai Small Scale Industries & Women's Sewing Training Centre | Empowering Women with Vocational Skills
              </title>
              <meta
                name="description"
                content="Explore the Shilatai Small Scale Industries & Women's Sewing Training Centre, supported by the Akka Foundation. Empowering women with vocational skills and business support."
              />
              <meta
                name="keywords"
                content="Shilatai Small Scale Industries, Women's Sewing Training Centre, Akka Foundation, women's vocational training, rural women empowerment, small-scale industries, sewing training, support for farmer suicide families, economic upliftment for women"
              />
              <link
                rel="canonical"
                href="https://akkafoundation.in/sustainableArt/shilatai"
              />
            </Helmet>
            <PageTop text={data.shilatai_name} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.shilatai_intro}
              span={data.shilatai_span}
              title={data.shilatai_heading}
              ctatext={data.shilatai_cta}
            />

            <div className="mangesh parent">
              <div className="mangesh-container container-small">
                {/* {fetcheddata &&
                  fetcheddata.map((item, index) => (
                    <ArtImage
                      key={index}
                      open={open}
                      image={item.image}
                      onClick={() => setSelectedId(item.id)}
                    />
                  ))} */}

                <ArtSwiper
                  data={fetcheddata}
                  open={open}
                  set={setSelectedId}
                  toggle={props.toggle}
                />
              </div>
            </div>

            {toggle && (
              <ArtDetail close={close} id={selectedId} data={props.data} />
            )}
          </>
        );
      })}

      <ArtistContact toggle={props.toggle} />
    </>
  );
};

export default Shilatai;
