import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/art--slider.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import SimpleCard from "./SimpleCard";
import img1 from '../Img/ourwork/2018.webp'
import img2 from '../Img/ourwork/2019.webp'
import img3 from '../Img/ourwork/2020.webp'
import img4 from '../Img/ourwork/2021.webp'
import img5 from '../Img/ourwork/2022.webp'
import img6 from '../Img/ourwork/2023.webp'
import img7 from '../Img/ourwork/2024.webp'
function ArtSlider(props) {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
      {props.data.map((data,index)=> {
        return(
          <>
          <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 4500000000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
        <SwiperSlide>
          <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img1})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider1_title} <span> {data.AC_slider1_span}  </span> </h3>

            <ul>
              <li>{data.AC_slider1_li1}</li>
              <li>{data.AC_slider1_li2}</li>
              <li>{data.AC_slider1_li3}</li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img2})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider2_title} <span> {data.AC_slider2_span}  </span> </h3>

            <ul>
              <li>{data.AC_slider2_li1}</li>
              <li>{data.AC_slider2_li2}</li>
              <li>{data.AC_slider2_li3}</li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img3})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider3_title} <span> {data.AC_slider3_span}  </span> </h3>

            <p>{data. AC_slider3_desc}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img4})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider4_title} <span> {data.AC_slider4_span}  </span> </h3>

            <ul>
              <li>{data.AC_slider4_li1}</li>
              <li>{data.AC_slider4_li2}</li>
              <li>{data.AC_slider4_li3}</li>
              <li>{data.AC_slider4_li4}</li>
              <li>{data.AC_slider4_li5}</li>
              <li>{data.AC_slider4_li6}</li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img5})`}} >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider5_title} <span> {data.AC_slider5_span}  </span> </h3>

            <ul>
              <li>{data.AC_slider5_li1}</li>
              <li>{data.AC_slider5_li2}</li>
              <li>{data.AC_slider5_li3}</li>
              <li>{data.AC_slider5_li4}</li>
              <li>{data.AC_slider5_li5}</li>
              <li>{data.AC_slider5_li6}</li>
              <li>{data.AC_slider5_li7}</li>
              <li>{data.AC_slider5_li8}</li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img6})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider6_title} <span> {data.AC_slider6_span}  </span> </h3>

            <ul>
              <li>{data.AC_slider6_li1}</li>
              <li>{data.AC_slider6_li2}</li>
              <li>{data.AC_slider6_li3}</li>
              
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="img-box">
            <div className="img bg-img" style={{background: `url(${img7})`}}  >

            </div>
          </div>
          <div className="card-list">
            <h3> {data.AC_slider7_title} <span> {data.AC_slider7_span}  </span> </h3>

            <ul>
              <li>{data.AC_slider7_li1}</li>
              <li>{data.AC_slider7_li2}</li>
              <li>{data.AC_slider7_li3}</li>
              
            </ul>
          </div>
        </SwiperSlide>
      
       

        
      </Swiper>
          </>
        )
      })}
    </>
  );
}

export default ArtSlider;
