import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "../styles/video.css";
import ReactPlayer from 'react-player'
const { useState } = React;
const Video = () => {
 
  return (
    <>
      <div className='video-gallery-parent '>
        <div className='video-gallery-container'>
          <div className='video-gallery-box'>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=Auk2ye81khc&t=9s' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=UDQQrjMrCys&t=1s' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=bs2Yvp_NQ9M' />
            </div>
            <div className='first-video'  >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=vCqYfL1SmhQ&t=44s' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=NIPC3ybkL6Q&t=42s' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=BFz2B-jIUaM&t=2s' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=U2Le2g8cU4k&t=1s' />
            </div>
            <div className='first-video' >
              <ReactPlayer className="video_player" controls url='https://www.youtube.com/watch?v=0pPnKzwclWU' />
            </div>


          </div>
        </div>
        
      </div>
      

     


    </>
  );
}

export default Video