import React from "react";
import "../styles/donate.css";
import PageTop from "../components/PageTop";
import { Link } from "react-router-dom";
import donatetp from "../Img/donate.webp";
import { Helmet } from "react-helmet";
const Donatepage = (props) => {
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <Helmet>
              <title>
                Empowering Lives: Akka Foundation and Our Vision for Women's
                Empowerment, Anandi, Drushti Abhiyan, and Health Guidelines
              </title>
              <meta
                name="description"
                content="Discover Akka Foundation's mission – empowering women, initiatives like Anandi and Drushti Abhiyan, and health guidelines."
              />
              <meta
                name="keywords"
                content="akka, akkafoundation, womens empowerment, anandi, project anandi, education, health, drushti abhiyan, founders, donation, health guidelines, environment"
              />
              <link rel="canonical" href="https://akkafoundation.in/donate" />
            </Helmet>
            <PageTop
              text={data.donate_pt_title}
              url={donatetp}
              alt="donate page image"
            />
            <div
              className="donate-parent parent"
              style={{
                background: `${props.bgcolor}`,
              }}
            >
              <div className="donate-container container-small">
                <h2>
                  {data.bf_tilte}
                  <span>{data.bf_title_span}</span>
                </h2>

                <div className="big-button-box">
                  <Link
                    to="https://rzp.io/l/project-anandi-donation"
                    target="_blank"
                    className="big-button bigbutton1"
                  >
                    <h3> {data.DP_food_title}</h3>
                  </Link>
                  <Link
                    to="https://rzp.io/l/kuvuRR1v"
                    target="_blank"
                    className="big-button bigbutton2"
                  >
                    <h3> {data.DP_food_patient}</h3>
                  </Link>
                  <Link
                    to="https://rzp.io/l/ZU7TJOnb3"
                    target="_blank"
                    className="big-button bigbutton3"
                  >
                    <h3>{data.DP_food_equipment}</h3>
                  </Link>
                  <Link
                    to="https://rzp.io/l/Y6ubbF8Bw4"
                    target="_blank"
                    className="big-button bigbutton4"
                  >
                    <h3> {data.DP_food_education}</h3>
                  </Link>
                  <Link
                    to="https://rzp.io/l/3J2iI48sq"
                    target="_blank"
                    className="big-button bigbutton5"
                  >
                    <h3> {data.DP_food_health}</h3>
                  </Link>
                  <Link
                    to="https://rzp.io/l/MjwLRvx7p"
                    target="_blank"
                    className="big-button bigbutton6"
                  >
                    <h3> {data.environment}</h3>
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default Donatepage;
