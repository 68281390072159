import React, { useEffect, useState } from "react";
import "../styles/empower.css";
import HeroCard from "../components/HeroCard";
import Ourworkcarousel from "../components/Ourworkcarousel";
import BeforeFooter from "../components/BeforeFooter";
import { Helmet } from "react-helmet";
import PageTop from "../components/PageTop";
import SimpleCard from "../components/SimpleCard";
import CountUp from "react-countup";
import empower from "../Img/empower.webp";
import empowerhc from "../Img/gallery-page/50.webp";
import AboutCarousel from "../components/AboutCarousel";
import empowers1 from "../Img/ourwork/e1.webp";
import empowers2 from "../Img/ourwork/e2.webp";
import { Tilt } from "react-tilt";
import empowers3 from "../extra/_MG_1762.webp";
import empowers4 from "../extra/345594202_947547596587389_3448222367530300439_n.webp";
import empowers5 from "../extra/319681789_1690316214699357_8470958883505488812_n.webp";
import { useInView } from "react-intersection-observer";
const Empower = (props) => {
  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };
  const images = [empowers1, empowers2, empowers3, empowers4, empowers5];
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
      if (inView1) {
        setIsVisible1(true);
      }
    }
  }, [inView, inView1]);
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
            <Helmet>
              <title>Empowerment Hub: Akka Foundation's Initiatives for Women, Farmers, and Community Growth </title>
              <meta name="description" content="Akka Foundation: Empowering women, farmers, and communities through skill development, government schemes, and support for a positive change. " />
              <meta name="keywords" content="akka foundation, women, women empowerment, government schemes, skill development, samvedna, poor farmers" />
              <link rel="canonical" href="https://akkafoundation.in/empower/" />
            </Helmet>
            <PageTop text={data.EM_pt_title} url={empower} />
            <HeroCard
              align="row"
              url={empowerhc}
              desc={data.EM_hc_desc}
              span={data.EM_hc_span}
              title={data.EM_hc_title}
            />

            <div className="independent-empower parent">
              <div className="independent-empower-container container-small">
                <div className="independent-left">
                  <h2>
                    {data.EM_id1_title} <span>{data.EM_id1_span}</span>
                  </h2>
                  <p>{data.EM_id1_desc}</p>
                </div>
                <div className="independent-right">
                  <Tilt
                    options={defaultOptions}
                    className="right-box bg-img shin"
                  ></Tilt>
                </div>
              </div>
            </div>

            <div className="independent-her parent">
              <div className="independent-her-container container-small">
                <h2>
                  {data.EM_id2_title} <span> {data.EM_id2_span} </span>
                </h2>
                <div className="independent-her-cards">
                  <SimpleCard
                    desc={data.EM_id3_card1_desc}
                    title={data.EM_id3_card1_title}
                  />
                  <SimpleCard
                    desc={data.EM_id3_card2_desc}
                    title={data.EM_id3_card2_title}
                  />
                </div>
              </div>
            </div>

            <div className="independent-disabled parent">
              <div className="independent-disable-container container-small">
                <div className="disabled-top">
                  <h2>
                    {data.EM_id4_left_title}{" "}
                    <span>{data.EM_id4_left_span}</span>
                  </h2>
                  <p>{data.EM_id4_left_desc}</p>
                </div>

                <div className="disabled-bottom">
                  <div className="disabled-left">
                    <Tilt
                      options={defaultOptions}
                      className="left-box bg-img shine"
                    ></Tilt>
                  </div>
                  <div className="disabled-right">
                    <h3>
                      {data.EM_id4_right_title}{" "}
                      <span>{data.EM_id4_right_span}</span>{" "}
                    </h3>

                    <div className="counter">
                      <div className="count-box" ref={ref}>
                        {isVisible && (
                          <h2>
                            <CountUp end={13371} />
                          </h2>
                        )}
                      </div>

                      <p>{data.EM_id4_right_para1}</p>
                    </div>
                    <div className="counter" ref={ref1}>
                      <div className="count-box">
                        {isVisible1 && (
                          <h2>
                            <CountUp end={8797} />
                          </h2>
                        )}
                      </div>

                      <p>{data.EM_id4_right_para2}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="independent-poor parent">
              <div className="independent-poor-container container-small">
                <h2>
                  {data.EM_id5_title} <span>{data.EM_id5_span}</span>
                </h2>

                <div className="poor-cards">
                  <SimpleCard
                    title={data.EM_id6_card1_title}
                    desc={data.EM_id6_card1_desc}
                  />
                  <SimpleCard
                    title={data.EM_id6_card2_title}
                    desc={data.EM_id6_card2_desc}
                  />
                  <SimpleCard
                    title={data.EM_id6_card3_title}
                    desc={data.EM_id6_card3_desc}
                  />
                </div>
              </div>
            </div>

            <div className="empower-carousel parent">
              <div className="empower-carousel-container container-small">
                <h2> {data.EM_is_title} </h2>

                <div className="carousel-box">
                  <AboutCarousel images={images} />
                </div>
              </div>
            </div>
            <BeforeFooter bgcolor="var(--baiege)" data={props.data} />
          </>
        );
      })}
    </>
  );
};

export default Empower;
